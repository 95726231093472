import { removeObjectFromArray, replaceObjectInArray } from '../helpers';

import {
  REPLACE_ORDER_PENDING_COLDSTORE_RELEASE,
  REMOVE_ORDER_PENDING_COLDSTORE_RELEASE,
  RELEASE_PDF_EMAIL_SENT,
  ORDERS_PENDING_COLDSTORE_RELEASE_FETCHED,
} from '../actions/actions_orders_pending_coldstore_release';

const orders_pending_coldstore_release = (orders_pending_coldstore_release_ = [], action) => {
  const ordersPendingColdstoreReleaseCopy = JSON.parse(JSON.stringify(orders_pending_coldstore_release_));
  switch (action.type) {
    case ORDERS_PENDING_COLDSTORE_RELEASE_FETCHED:
      return action.payload;
    case REPLACE_ORDER_PENDING_COLDSTORE_RELEASE:
      return replaceObjectInArray(ordersPendingColdstoreReleaseCopy, action.payload);
    case RELEASE_PDF_EMAIL_SENT:
    case REMOVE_ORDER_PENDING_COLDSTORE_RELEASE:
      return removeObjectFromArray(ordersPendingColdstoreReleaseCopy, action.payload);
    default:
      return orders_pending_coldstore_release_;
  }
};

export default orders_pending_coldstore_release;
