import { removeObjectFromArray, replaceObjectInArray } from '../helpers';
import {
  SHIPPING_LINE_ADDED,
  SHIPPING_LINE_UPDATED,
  SHIPPING_LINES_FETCHED,
  SHIPPING_LINES_REMOVED,
} from '../actions/actions_shipping_lines';

const shipping_lines = (shipping_lines_ = [], action) => {
  const shippingLineCopy = JSON.parse(JSON.stringify(shipping_lines_));

  switch (action.type) {
    case SHIPPING_LINES_FETCHED:
      return action.payload;
    case SHIPPING_LINE_ADDED:
      shippingLineCopy.push(action.payload);
      return shippingLineCopy;
    case SHIPPING_LINE_UPDATED:
      return replaceObjectInArray(shippingLineCopy, action.payload);
    case SHIPPING_LINES_REMOVED:
      return removeObjectFromArray(shippingLineCopy, action.payload);
    default:
      return shipping_lines_;
  }
};

export default shipping_lines;
