import { PASSWORD_RESET } from '../actions/actions_auth';

const password_reset = (password_reset_ = false, action) => {
  switch (action.type) {
    case PASSWORD_RESET:
      return action.payload;
    default:
      return password_reset_;
  }
};

export default password_reset;
