import { PRODUCTION_PLANNER_EDITED, PRODUCTION_PLANNER_FETCHED } from '../actions/actions_production_planner';

function replaceProductionPlanner(forec, edited_forecast) {
  const newForecast = [];
  forec.forEach(cast => {
    if (
      cast.output_product_uid === edited_forecast[0].output_product_uid &&
      cast.grinder_uid === edited_forecast[0].grinder_uid &&
      cast.date === edited_forecast[0].date
    ) {
      newForecast.push(edited_forecast[0]);
    } else {
      newForecast.push(cast);
    }
  });
  return newForecast;
}

const production_planner = (forecasts = [], action) => {
  const copyForecasts = JSON.parse(JSON.stringify(forecasts));
  const newForecast = replaceProductionPlanner(copyForecasts, action.payload);
  switch (action.type) {
    case PRODUCTION_PLANNER_FETCHED:
      return action.payload;
    case PRODUCTION_PLANNER_EDITED:
      return newForecast;
    default:
      return forecasts;
  }
};

export default production_planner;
