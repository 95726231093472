import { combineReducers } from 'redux';
import annotations from '../slices/annotations/annotations';
import attachments from '../slices/basic/file-upload';
// slices
import access_levels from './reducer_access_levels';
import active_edit from './reducer_active_edit';
import article from './article';
import articleRegion from '../slices/article-region/articleRegionSlice';
import articles from './articles';
import balance_sheet from './reducer_balance_sheet';
import bulkUpdater from '../slices/bulk-updater/bulkUpdaterSlice';
import buyActivity from './reducer_buy_activity';
import cold_stores from './reducer_cold_stores';
import coldstore_orders_util from './reducer_coldstore_orders_util';
import configs from './reducer_config';
import current_affairs from './reducer_current_affairs';
import current_modal_detail from './reducer_current_modal_detail';
import daily_task_detail from './reducer_daily_tasks_detail';
import data_management from './reducer_data_management';
import delist_packer_report from './reducer_delist_packer_report';
import email from './reducer_email';
import entityManager from '../slices/masterData/entityManagerSlice';
import error from './reducer_error';
import exception_assignments from './reducer_exception_assignments';
import fdr_items from './reducer_fdr_items';
import fdr_rates from './reducer_fdr_rates';
import fdr_freight_rates from './reducer_fdr_freight_rates';
import fecs from './reducer_fecs';
import finance_factorings from './reducer_finance_factorings';
import form_fields from './reducers_form_fields';
import grinders from './reducer_grinders';
import input_product_inventory_items from './reducer_input_product_inventory_items';
import input_product_inventory_items_search from './reducer_input_product_inventory_items_search';
import input_product_inventory_levels from './reducer_input_product_inventory_levels';
import input_products from './reducer_input_products';
import interests from './reducer_interests';
import invoicing from './reducer_invoicing';
import kpi from '../slices/kpi/kpiSlice';
import { reducer as orderActionsFormatter } from '../components/gridHelpers/OrderActionsFormatter';
import kpis from './reducer_kpis';
import load_sizes from './reducers_load_sizes';
import map_data from './reducer_map';
import markets from '../slices/markets/marketReducers';
import marketsVolumeDetail from '../slices/markets-details/volumeDetails';
import notifying_action_groups from './reducer_notifying_action_groups';
import optimizer_beef_forecast from './reducer_optimizer_beef_forecast';
import optimizer_bsm_orders from './reducer_optimizer_bsm_orders';
import optimizer_data from './reducer_optimizer';
import orders from './reducer_orders';
import orders_coldstore from './reducer_orders_coldstore';
import orders_coldstore_released from './reducer_orders_coldstore_released';
import orders_pending_coldstore_release from './reducer_orders_pending_coldstore_release';
import orders_pending_coldstore_schedule from './reducer_orders_pending_coldstore_schedule';
import organisations from './reducer_organisations';
import output_product_inventory_items from './reducer_output_product_inventory_items';
import output_products from './reducer_output_products';
import packer_offers from './reducer_packer_offer';
import packer_plants from './reducer_packer_plants';
import packerInvoiceOrders from './reducer_invoicing_drawdown_reports';
import packers from './reducer_packers';
import password_reset from './reducer_password';
import po_documents from './reducer_po_documents';
import pologs from './reducer_pologs';
import ports from './reducer_ports';
import production_planner from './reducer_production_planner';
import quality from '../slices/quality/qualityReducers';
import qualityClaims from '../slices/quality-details-claims/qualityDetailsClaimsReducers';
import qualityGrinders from '../slices/quality-grinders/qualityGrindersReducers';
import qualityNCRs from '../slices/quality-details-ncrs/qualityDetailsNcrsReducers';
import qualityPackerPlants from '../slices/quality-packer-plants/qualityPackerPlantsReducers';
import roles from './reducer_roles';
import selfServiceReportConfig from './reducer_self_service_report_config';
import selfServiceReports from './reducer_self_service_reports';
import settingsConfig from './reducer_settings_config';
import shipping_lines from './reducer_shipping_lines';
import strategies from '../slices/strategies/strategiesReducers';
import strategyPrices from '../slices/strategies/strategyPricesReducers';
import strategyTypes from '../slices/strategies/strategyTypesReducers';
import supplyChainDetails from '../slices/markets-details/supplyChainDetails';
import poHistory from '../slices/po-history/poHistoryReducers';
import support_emails from './reducer_support_emails';
import tickets from './reducer_tickets';
import theme from './reducer_theme';
import themes from './reducer_themes';
import transport from './reducer_transport';
import transporters from './reducer_transporters';
import treasury from '../slices/treasury/treasuryReducers';
import treasuryDetails from '../slices/treasury-details/treasuryDetailsReducers';
import units_of_measure from './reducer_unit_of_measure';
import user from './reducer_user';
import user_documents from './reducer_user_files';
import users from './reducer_users';
import voyage from './reducer_voyage';
import voyage_legs from './reducer_voyage_leg';
import end_users from './reducer_end_user';
import packageWeights from './reducer_package_weights';
import specifications from './reducer_specifications';
import ncrs from './reducer_ncrs';
import navigation from './reducer_navigation';
import ncrChat from './reducer_ncr_chat';
import ncrHistory from './reducer_ncr_history';
import orderStatusMap from '../slices/order-status-map/orderStatusMapSlice';
import food_safety_quality from './reducer_food_safety_quality';
import establishmentDetails from './reducer_establishment_details';

const rootReducer = combineReducers({
  access_levels,
  active_edit,
  annotations,
  article,
  articleRegion,
  articles,
  attachments,
  balance_sheet,
  bulkUpdater,
  buyActivity,
  cold_stores,
  coldstore_orders_util,
  configs,
  current_affairs,
  current_modal_detail,
  daily_task_detail,
  data_management,
  delist_packer_report,
  email,
  end_users,
  entityManager,
  error,
  exception_assignments,
  fdr_items,
  fdr_rates,
  fdr_freight_rates,
  fecs,
  finance_factorings,
  form_fields,
  grinders,
  input_product_inventory_items_search,
  input_product_inventory_items,
  input_product_inventory_levels,
  input_products,
  interests,
  invoicing,
  kpi,
  kpis,
  orderActionsFormatter,
  load_sizes,
  map_data,
  markets,
  marketsVolumeDetail,
  notifying_action_groups,
  optimizer_beef_forecast,
  optimizer_bsm_orders,
  optimizer_data,
  orders_coldstore_released,
  orders_coldstore,
  orders_pending_coldstore_release,
  orders_pending_coldstore_schedule,
  orders,
  organisations,
  output_product_inventory_items,
  output_products,
  packer_offers,
  packer_plants,
  packerInvoiceOrders,
  packers,
  password_reset,
  po_documents,
  pologs,
  ports,
  production_planner,
  quality,
  qualityClaims,
  qualityGrinders,
  qualityNCRs,
  qualityPackerPlants,
  roles,
  selfServiceReportConfig,
  selfServiceReports,
  settingsConfig,
  shipping_lines,
  strategies,
  strategyPrices,
  strategyTypes,
  supplyChainDetails,
  support_emails,
  tickets,
  theme,
  themes,
  transport,
  transporters,
  treasury,
  treasuryDetails,
  units_of_measure,
  user_documents,
  user,
  users,
  voyage_legs,
  voyage,
  packageWeights,
  poHistory,
  specifications,
  ncrs,
  navigation,
  ncr_chat: ncrChat,
  ncr_history: ncrHistory,
  orderStatusMap,
  food_safety_quality,
  establishmentDetails,
});

export default rootReducer;
