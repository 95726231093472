import {
  REQUEST_INVOICING_ORDERS,
  LOAD_INVOICING_ORDERS,
  BEFORE_CREATE_AND_SEND_INVOICES,
  CREATE_AND_SEND_INVOICES,
  GENERATE_INVOICE,
  GENERATE_INVOICES,
  LOAD_INVOICE_SUMMARY,
  CLEAR_INVOICE_ERROR,
  RESET_INVOICING_STATE,
  ERROR_GENERATE_INVOICE,
  ERROR_GENERATE_INVOICES,
  ERROR_CREATE_AND_SEND_INVOICES,
  ERROR_GENERATE_INVOICE_SUMMARY,
} from '../actions/actions_invoicing';

import {
  CART_PRICED_ORDERS_FETCHED,
  PRICED_ORDERS_FETCHED,
  PRICED_ORDERS_ERROR,
  CLEAR_PRICED_ORDERS,
  PRICED_ORDERS_REQUESTED,
  PRICED_CART_ORDERS_REQUESTED,
} from '../actions/actions_priced_orders';

const initialState = {
  invoiceList: [],
  pricedOrderList: [],
  pricedCartOrderList: [],
  orderList: [],
  orderPagination: {},
  isLoadingPricedOrders: false,
  isLockingPrices: false,
  invoicePDFList: [],
  sentInvoiceList: [],
  error: null,
  isSendingInvoices: false,
};

const invoicingReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_INVOICING_ORDERS: {
      return {
        ...state,
        isLoadingPricedOrders: true,
      };
    }
    case LOAD_INVOICING_ORDERS: {
      const orderList = action.orders.reduce(
        (agg, order) => [
          ...agg,
          {
            ...order.purchase_order,
            grinder: order.purchase_order.grinder,
            packer_plant: order.purchase_order.packer_plant,
            priceBucket: order.price_bucket,
          },
        ],
        []
      );
      return {
        ...state,
        orderList,
        isLoadingPricedOrders: false,
        orderPagination: action.pagination,
      };
    }
    case BEFORE_CREATE_AND_SEND_INVOICES: {
      return {
        ...state,
        isSendingInvoices: action.isSendingInvoices,
      };
    }
    case CREATE_AND_SEND_INVOICES: {
      return {
        ...state,
        error: null,
        sentInvoiceList: action.invoices,
        isSendingInvoices: action.isSendingInvoices,
      };
    }
    case GENERATE_INVOICE: {
      return {
        ...state,
        error: null,
        invoicePDFList: [action.invoice],
      };
    }
    case GENERATE_INVOICES: {
      return {
        ...state,
        error: null,
        invoicePDFList: action.invoices,
      };
    }
    case ERROR_GENERATE_INVOICE:
      return {
        ...state,
        error: 'An error occurred when trying to download the invoice',
      };
    case ERROR_GENERATE_INVOICES:
      return {
        ...state,
        error: 'An error occurred when trying to download the invoice(s)',
      };
    case ERROR_CREATE_AND_SEND_INVOICES: {
      return {
        ...state,
        error: 'An error occurred when trying to email invoice(s)',
        isSendingInvoices: action.isSendingInvoices,
      };
    }
    case CLEAR_INVOICE_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    case ERROR_GENERATE_INVOICE_SUMMARY: {
      return {
        ...state,
        error: 'An error occurred when trying to generate the invoice breakdown',
      };
    }

    case LOAD_INVOICE_SUMMARY: {
      return {
        ...state,
        invoiceSummary: action.invoiceSummary,
      };
    }

    case RESET_INVOICING_STATE: {
      return {
        ...state,
        invoicePDFList: [],
        sentInvoiceList: [],
        invoiceSummary: null,
        error: null,
      };
    }

    case PRICED_ORDERS_FETCHED: {
      return {
        ...state,
        pricedOrderList: action.payload,
        isLoadingPricedOrders: false,
      };
    }

    case CLEAR_PRICED_ORDERS: {
      return {
        ...state,
        pricedOrderList: [],
      };
    }

    case PRICED_ORDERS_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case PRICED_ORDERS_REQUESTED: {
      return {
        ...state,
        isLoadingPricedOrders: true,
      };
    }

    case PRICED_CART_ORDERS_REQUESTED: {
      return {
        ...state,
        isLockingPrices: action.isLockingPrices,
      };
    }

    case CART_PRICED_ORDERS_FETCHED: {
      return {
        ...state,
        pricedCartOrderList: action.payload,
        isLockingPrices: action.isLockingPrices,
      };
    }

    default:
      return state;
  }
};

export default invoicingReducer;
