import { removeObjectFromArray, replaceObjectInArray } from '../helpers';
import {
  SPECIFICATIONS_ADDED,
  SPECIFICATIONS_FETCHED,
  SPECIFICATIONS_REMOVED,
  SPECIFICATIONS_UPDATED,
} from '../actions/actions_specifications';

const specifications = (specifications_ = [], action) => {
  const specificationsCopy = JSON.parse(JSON.stringify(specifications_));

  switch (action.type) {
    case SPECIFICATIONS_FETCHED:
      return action.payload;
    case SPECIFICATIONS_ADDED:
      specificationsCopy.push(action.payload);
      return specificationsCopy;
    case SPECIFICATIONS_UPDATED:
      return replaceObjectInArray(specificationsCopy, action.payload);
    case SPECIFICATIONS_REMOVED:
      return removeObjectFromArray(specificationsCopy, action.payload);
    default:
      return specifications_;
  }
};

export default specifications;
