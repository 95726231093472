import { ConfigProvider, Empty, List } from 'antd';
import React from 'react';
import styles from './AnnotationList.module.css';
import { useSelector } from 'react-redux';
import { selectTreasuryDetailsShowOutlook } from '../../slices/treasury-details/treasuryDetailsReducers';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { Text } from '@chakra-ui/react';

function AnnotationList(props) {
  const { editAnnotation, intervalsUntilCurrent, annotationIntervalFormat, annotations } = props;
  const showOutlook = useSelector(selectTreasuryDetailsShowOutlook);

  const annotationsFiltered = annotations.filter(annotation => {
    if (!showOutlook && intervalsUntilCurrent) {
      return intervalsUntilCurrent.some(t => t === format(annotation.timestamp(annotationIntervalFormat)));
    }
    return true;
  });

  return (
    <ConfigProvider
      renderEmpty={() => (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<Text style={{ opacity: 0.4 }}>NO ANNOTATIONS</Text>}
        />
      )}
    >
      <List
        itemLayout="horizontal"
        dataSource={annotationsFiltered}
        style={{ cursor: 'pointer' }}
        renderItem={(item, index) => (
          <List.Item
            onClick={() => editAnnotation(item)}
            style={{
              width: '100%',
              alignItems: 'flex-start',
              flexDirection: 'column',
            }}
          >
            <div>
              <div className={styles.index}>{index + 1}</div>
              <Text className={styles.index}>{format(item.timestamp, annotationIntervalFormat)}</Text>
            </div>

            <Text className={styles.paragraph}>{item.content}</Text>
          </List.Item>
        )}
      />
    </ConfigProvider>
  );
}

AnnotationList.propTypes = {
  editAnnotation: PropTypes.func,
  intervalsUntilCurrent: PropTypes.instanceOf([]),
  annotationIntervalFormat: PropTypes.string,
  annotations: PropTypes.instanceOf([]),
};

export default AnnotationList;
