import {
  ORGANISATIONS_FETCHED,
  ORGANISATION_ADDED,
  ORGANISATION_EDIT,
  ORGANISATION_REMOVED,
} from '../actions/actions_organisations';

function replaceOrganisation(allOrganisations, editedOrganisation) {
  const newOrganisations = [];
  allOrganisations.forEach(element => {
    if (element.id === editedOrganisation.id) {
      newOrganisations.push(editedOrganisation);
    } else {
      newOrganisations.push(element);
    }
  });
  return newOrganisations;
}

function removeOrganisation(allOrganisations, removedOrganisation) {
  const newOrganisations = [];
  allOrganisations.forEach(element => {
    if (element.id !== removedOrganisation.id) {
      newOrganisations.push(element);
    }
  });
  return newOrganisations;
}

const organisations = (org = [], action) => {
  const newOrganisations = JSON.parse(JSON.stringify(org));
  switch (action.type) {
    case ORGANISATIONS_FETCHED:
      return action.payload;
    case ORGANISATION_ADDED:
      newOrganisations.push(action.payload);
      return newOrganisations;
    case ORGANISATION_EDIT:
      return replaceOrganisation(newOrganisations, action.payload);
    case ORGANISATION_REMOVED:
      return removeOrganisation(newOrganisations, action.payload);
    default:
      return org;
  }
};

export default organisations;
