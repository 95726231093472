import {
  BEEF_OUTPUT_PRODUCTS_FETCHED,
  BEEF_OUTPUT_PRODUCT_ADDED,
  BEEF_OUTPUT_PRODUCT_EDIT,
  BEEF_OUTPUT_PRODUCT_REMOVED,
} from '../actions/actions_output_products';

function replaceBeefOutputProduct(allBeefOutputProducts, editedBeefOutputProduct) {
  const newBeefOutputProducts = [];
  allBeefOutputProducts.forEach(element => {
    if (element.uid === editedBeefOutputProduct.uid) {
      newBeefOutputProducts.push(editedBeefOutputProduct);
    } else {
      newBeefOutputProducts.push(element);
    }
  });
  return newBeefOutputProducts;
}

function removeBeefOutputProduct(allBeefOutputProducts, removedBeefOutputProduct) {
  const newBeefOutputProducts = [];
  allBeefOutputProducts.forEach(element => {
    if (element.uid !== removedBeefOutputProduct.uid) {
      newBeefOutputProducts.push(element);
    }
  });
  return newBeefOutputProducts;
}

const output_products = (op = [], action) => {
  const newBeefOutputProducts = JSON.parse(JSON.stringify(op));
  switch (action.type) {
    case BEEF_OUTPUT_PRODUCTS_FETCHED:
      return action.payload;
    case BEEF_OUTPUT_PRODUCT_ADDED:
      newBeefOutputProducts.push(action.payload);
      return newBeefOutputProducts;
    case BEEF_OUTPUT_PRODUCT_EDIT:
      return replaceBeefOutputProduct(newBeefOutputProducts, action.payload);
    case BEEF_OUTPUT_PRODUCT_REMOVED:
      return removeBeefOutputProduct(newBeefOutputProducts, action.payload);
    default:
      return op;
  }
};

export default output_products;
