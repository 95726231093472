import { FDR_RATES_FETCHED, FDR_RATE_UPDATED, FDR_RATE_REMOVED, FDR_RATE_ADDED } from '../actions/actions_fdr_rates';

const fdr_rates = (FDRRates = [], action) => {
  switch (action.type) {
    case FDR_RATES_FETCHED:
      return action.payload;
    case FDR_RATE_ADDED:
      return [...FDRRates, action.payload];
    case FDR_RATE_UPDATED:
      return [...FDRRates.filter(item => item.id !== action.payload.id), action.payload];
    case FDR_RATE_REMOVED:
      return [...FDRRates.filter(item => item.id !== action.payload.id)];
    default:
      return FDRRates;
  }
};

export default fdr_rates;
