import { DELIST_PACKES_REPORT_FETCHED } from '../actions/actions_delist_packers_report';

const delist_packer_report = (delist_packer_report_ = [], action) => {
  switch (action.type) {
    case DELIST_PACKES_REPORT_FETCHED:
      return action.payload;
    default:
      return delist_packer_report_;
  }
};

export default delist_packer_report;
