function firstNonZeroDigit(value) {
  const matched = value.toString().match(/([1-9]{1})/);

  if (matched) {
    return matched[0];
  }
  return 0;
}

export function calculateRoundedMax(value) {
  // Bring to a clean numbers.

  // You kind of want significant digits rounded up,
  // but don't want to ruin already clean values,
  // especially 1.0 and 100 which are common known maxes
  // for percentages

  // Take the first significant digit
  // If it = value, return it
  // Otherwise, add "1" of that order of magnitude

  // e.g.:

  // [
  //   calculateRoundedMax(-62) === -60,
  //   calculateRoundedMax(-67) === -60,
  //   calculateRoundedMax(-1) === -1,
  //   calculateRoundedMax(0.1) === 0.1,
  //   calculateRoundedMax(0) === 0,
  //   calculateRoundedMax(1) === 1,
  //   calculateRoundedMax(10) === 10,
  //   calculateRoundedMax(100) === 100,
  //   calculateRoundedMax(1000) === 1000,
  //   calculateRoundedMax(10000) === 10000,
  //   calculateRoundedMax(0.622) === 0.7,
  //   calculateRoundedMax(6.4) === 7,
  //   calculateRoundedMax(64) === 70,
  //   calculateRoundedMax(635) === 700,
  //   calculateRoundedMax(6273) === 7000,
  //   calculateRoundedMax(63211) === 70000,
  //   calculateRoundedMax(67221) === 70000,
  // ];

  const significantDigits = +value.toPrecision(1);

  if (significantDigits === value) {
    return value;
  }

  // Track negativeness of the value for some logic switches
  const isNegative = value < 0;

  if (!isNegative) {
    // When positive, if we rounded up with our significant figures, we don't need to do anything
    if (firstNonZeroDigit(value) !== firstNonZeroDigit(significantDigits)) {
      return significantDigits;
    }
    // When negative, if we rounded down, we flip the logic of above
  } else if (firstNonZeroDigit(value) === firstNonZeroDigit(significantDigits)) {
    return significantDigits;
  }

  // Find the order of magnitude
  const orderOfMagnitude = Math.floor(Math.log10(Math.abs(value)));

  // Turn the order of magnitude into something we will add to the number
  const addition = 10 ** orderOfMagnitude;

  // If we rounded down with our significant figures, we need to bump it up
  return significantDigits + addition;
}
