import React from 'react';
import { range, scaleBand, scaleLinear } from 'd3';
import styles from './BubbleChart.module.css';
import useDimensions from '../../modules/hooks/useDimensions';
import { Tooltip } from '@/components/ui/tooltip';
import { findSiDefinition } from '../../modules/format';
import { PropTypes } from 'prop-types';
import { random } from 'lodash';

const padding = {
  top: 10,
  bottom: 2,
  left: 10,
  right: 10,
};

const useDomains = (data, minData, maxData) => {
  return {
    x: Array.from(data.keys()),
    y: range(Math.max(...data.map(x => x.length))),
    z: [minData, maxData],
  };
};

const useScales = (dimensions, domains) => {
  if (!dimensions) return undefined;

  const frame = {
    top: padding.top,
    right: dimensions.width - padding.right,
    bottom: dimensions.height - padding.bottom,
    left: padding.left,
    width: dimensions.width - padding.right - padding.left,
    height: dimensions.height - padding.top - padding.bottom,
  };

  const x = scaleBand().domain(domains.x).range([frame.left, frame.right]).paddingInner(0);

  const y = scaleBand().domain(domains.y).range([frame.bottom, frame.top]).paddingInner(0);

  const z = scaleLinear().domain(domains.z).rangeRound([frame.left, frame.right]).nice();

  return {
    frame,
    x,
    y,
    z,
  };
};

const splitIntoBuckets = (array, min, max, numberOfBuckets, goal, withIntervals) => {
  const arrayWithBuckets = Array.from(Array(numberOfBuckets), () => []);
  if (withIntervals) {
    const si = findSiDefinition(max);
    const coef = si.value === 1 ? 10 : si.value;
    min = Math.floor(min / coef) * coef;
    max = Math.ceil(max / coef) * coef;
  }

  let arr = array;
  const isFractions = min <= 1 && max <= 1;
  if (isFractions) {
    min *= 100;
    max *= 100;
    arr = array.map(x => ({ ...x, value: x.value * 100 }));
  }
  let step = (max - min + 1) / numberOfBuckets;
  arr.forEach(x => {
    let tmp = x;
    if (isFractions) {
      tmp = { ...x, value: x.value / 100 };
    }
    arrayWithBuckets[Math.floor((x.value - min) / step)].push(tmp);
  });

  const retVal = {
    buckets: arrayWithBuckets,
    goalBucket: goal != null && Math.floor(((isFractions ? goal * 100 : goal) - min) / step),
  };

  return retVal;
};

export default function BubbleChart(props) {
  const {
    data,
    average,
    goal,
    isLarge,
    isSmall,
    getColor,
    highlights,
    format,
    formatTooltip,
    nameField,
    colorsLegend,
    minMaxGenerator,
    goalLine = false,
    withIntervals,
    withEmptyBubbles = false,
    debug = false,
  } = props;

  const minValData = Math.min(...data.map(x => x.value));
  const maxValData = Math.max(...data.map(x => x.value));

  let { minVal: minData, maxVal: maxData } = minMaxGenerator
    ? minMaxGenerator(maxValData, minValData)
    : { minVal: minValData, maxVal: maxValData };

  if (withIntervals) {
    const si = findSiDefinition(maxData);
    const coef = si.value === 1 ? 10 : si.value;
    minData = Math.floor(minData / coef) * coef;
    maxData = Math.ceil(maxData / coef) * coef;
  }

  const chartData = splitIntoBuckets(data, minData, maxData, 20, goal, withIntervals);

  const [ref, dimensions] = useDimensions();
  const domains = useDomains(chartData.buckets, minData, maxData);
  const scales = useScales(dimensions, domains);

  if (debug) {
    // eslint-disable-next-line no-console
    console.log('chartData', chartData);
    // eslint-disable-next-line no-console
    console.log('scales', scales);
    // eslint-disable-next-line no-console
    console.log('x', scales && scales.x(chartData.goalBucket));
  }

  return (
    <div style={{ width: '100%' }}>
      <div className={styles.BubbleChart_mainChartContainer}>
        <div className={styles.BubbleChart_chartContainer} style={{ height: isLarge ? 500 : isSmall ? 100 : 200 }}>
          <svg className={styles.BubbleChart_svg} ref={ref}>
            {scales && (
              <>
                <rect
                  x={10}
                  y={scales.frame.bottom - 8}
                  width={scales.frame.width}
                  height={2}
                  opacity={1}
                  fill="#0B1435"
                />
                {goal != null && goalLine && (
                  <line
                    x1={scales.x(chartData.goalBucket)}
                    x2={scales.x(chartData.goalBucket)}
                    y1={scales.frame.bottom - 10}
                    y2={10}
                    style={{
                      stroke: 'rgba(11, 20, 53, 0.6)',
                      strokeDasharray: '7,3',
                    }}
                  />
                )}
                {colorsLegend &&
                  colorsLegend.map(x => {
                    return (
                      <rect
                        key={x.color}
                        x={scales.z(x.start)}
                        y={scales.frame.bottom - 6}
                        width={scales.z(x.end) - scales.z(x.start)}
                        height={4}
                        fill={x.color}
                      />
                    );
                  })}
                {average && (
                  <svg
                    width="10"
                    height="7"
                    x={scales.z(average) - 5}
                    y={scales.frame.bottom - 5}
                    viewBox="0 0 10 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.17542 0.841732C4.57325 0.393851 5.27291 0.393852 5.67073 0.841733L9.44054 5.08591C10.0134 5.73089 9.55556 6.75 8.69289 6.75H1.15327C0.290591 6.75 -0.167281 5.73089 0.405612 5.08591L4.17542 0.841732Z"
                      fill="#0B1435"
                    />
                  </svg>
                )}
              </>
            )}
          </svg>
          {scales &&
            chartData.buckets.map((x, i) => {
              if (x.length) {
                return (
                  <div
                    key={`bucket-${random()}`}
                    style={{
                      width: scales.x.bandwidth(),
                      height: scales.frame.height,
                      position: 'absolute',
                      top: 0,
                      left: scales.x(i),
                      display: 'flex',
                      flexFlow: 'column-reverse',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}
                  >
                    {x.map((p, j) => {
                      return (
                        <Tooltip key={`packer-${random()}`} content={() => formatTooltip(p)}>
                          <div
                            style={{
                              width: 10,
                              height: 10,
                              borderRadius: '50%',
                              border: withEmptyBubbles ? '1px solid #D3D5E0' : 'none',

                              backgroundColor: highlights
                                ? !!highlights[p[nameField]]
                                  ? highlights[p[nameField]]
                                  : withEmptyBubbles
                                  ? '#fff'
                                  : '#D3D5E0'
                                : getColor(p.value),
                              margin: '2px',
                              backgroundImage: withEmptyBubbles
                                ? 'repeating-linear-gradient(-45deg, transparent, transparent 2px, rgba(255,255,255,.5) 2px, rgba(255,255,255,.5) 4px)'
                                : 'none',
                            }}
                          />
                        </Tooltip>
                      );
                    })}
                  </div>
                );
              }
            })}
        </div>
      </div>
      <svg style={{ width: '100%', height: '15px' }}>
        {scales && (
          <>
            <foreignObject x={10} y={0} width={60} height={15}>
              <div
                style={{
                  width: '100%',
                  lineHeight: 1,
                  fontWeight: 'bold',
                  textAlign: 'start',
                }}
              >
                {format(minData)}
              </div>
            </foreignObject>
            {goal && (
              <foreignObject x={scales.x(chartData.goalBucket)} y={0} width={60} height={15}>
                <div
                  style={{
                    width: '100%',
                    lineHeight: 1,
                    opacity: 0.6,
                    textAlign: 'start',
                  }}
                >
                  {format(goal)}
                </div>
              </foreignObject>
            )}
            {withIntervals && (
              <>
                {[(minData + maxData) / 4, (minData + maxData) / 2, ((minData + maxData) * 3) / 4].map((int, i) => (
                  <foreignObject x={scales.z(int)} y={0} width={60} height={15} key={i}>
                    <div
                      style={{
                        width: '100%',
                        lineHeight: 1,
                        fontWeight: 'bold',
                        textAlign: 'start',
                      }}
                    >
                      {format(int)}
                    </div>
                  </foreignObject>
                ))}
              </>
            )}
            <foreignObject x={scales.frame.right - 60} y={0} width={60} height={15}>
              <div
                style={{
                  width: '100%',
                  lineHeight: 1,
                  fontWeight: 'bold',
                  textAlign: 'end',
                }}
              >
                {format(maxData)}
              </div>
            </foreignObject>
          </>
        )}
      </svg>
    </div>
  );
}

BubbleChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
  average: PropTypes.number,
  goal: PropTypes.number,
  isLarge: PropTypes.bool,
  isSmall: PropTypes.bool,
  getColor: PropTypes.func,
  highlights: PropTypes.objectOf(PropTypes.string),
  format: PropTypes.func,
  formatTooltip: PropTypes.func,
  nameField: PropTypes.string,
  colorsLegend: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      start: PropTypes.number.isRequired,
      end: PropTypes.number.isRequired,
    })
  ),
  minMaxGenerator: PropTypes.func,
  goalLine: PropTypes.bool,
  withIntervals: PropTypes.bool,
  withEmptyBubbles: PropTypes.bool,
  debug: PropTypes.bool,
};
