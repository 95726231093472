import React, { useState, useEffect } from 'react';
import { ANNOTATION_MODAL_MODE } from '../../modules/hooks/useAnnotation';
import PropTypes from 'prop-types';
import { Button, Flex, Input } from '@chakra-ui/react';

import {
  DialogActionTrigger,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from '@/components/ui/dialog';

function AnnotationModal(props) {
  const { showModal, modalMode, modalAnnotation, onModalSubmit, closeModal, deleteAnnotation, allowTitle } = props;

  const [newContent, setNewContent] = useState(modalAnnotation ? modalAnnotation.content : '');

  const [newTitle, setNewTitle] = useState(modalAnnotation ? modalAnnotation.title : '');

  useEffect(() => {
    setNewContent(modalAnnotation ? modalAnnotation.content : '');
    setNewTitle(modalAnnotation ? modalAnnotation.title : '');
  }, [modalAnnotation]);

  return (
    <DialogRoot open={showModal} onOpenChange={e => !e.open && closeModal()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {modalMode === ANNOTATION_MODAL_MODE.ADD ? 'Create new annotation' : 'Edit annotation'}
          </DialogTitle>
        </DialogHeader>
        <DialogBody>
          {allowTitle && (
            <Input
              value={newTitle}
              onChange={e => setNewTitle(e.target.value)}
              style={{ marginBottom: 20 }}
              placeholder="Title"
            />
          )}
          <Input.TextArea
            rows={4}
            value={newContent}
            onChange={e => setNewContent(e.target.value)}
            placeholder="Annotation"
          />
        </DialogBody>
        <DialogFooter>
          <Flex gap="20px" justifyContent="flex-end">
            {modalMode === ANNOTATION_MODAL_MODE.EDIT && (
              <Button key="delete" danger onClick={() => deleteAnnotation(modalAnnotation)}>
                Delete
              </Button>
            )}
            <DialogActionTrigger asChild onClick={() => closeModal()}>
              <Button key="cancel">Cancel</Button>
            </DialogActionTrigger>

            <Button type="submit" onClick={() => onModalSubmit({ content: newContent, title: newTitle })}>
              {modalMode === ANNOTATION_MODAL_MODE.ADD ? 'Create' : 'Save'}
            </Button>
          </Flex>
        </DialogFooter>
        <DialogCloseTrigger />
      </DialogContent>
    </DialogRoot>
  );
}

AnnotationModal.propTypes = {
  showModal: PropTypes.bool,
  modalMode: PropTypes.string,
  modalAnnotation: PropTypes.instanceOf(Object),
  onModalSubmit: PropTypes.func,
  closeModal: PropTypes.func,
  deleteAnnotation: PropTypes.func,
  allowTitle: PropTypes.bool,
};

export default AnnotationModal;
