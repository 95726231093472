import { ChevronRight } from '../Icons';
import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@chakra-ui/react';

function DetailPageHeading({ children, includeChevron = false }) {
  return (
    <Text textStyle="2xl" style={{ fontWeight: '500' }}>
      {children}
      {includeChevron && (
        <div style={{ display: 'inline-block', width: '18px' }}>
          <ChevronRight />
        </div>
      )}
    </Text>
  );
}

DetailPageHeading.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  includeChevron: PropTypes.bool,
};

export default DetailPageHeading;
