import {
  LOAD_OPTIMIZER_DATA,
  OPTIMIZER_PACKER_PLANT_BEEF_INPUT_ADDED,
  OPTIMIZER_PACKER_PLANT_BEEF_INPUT_EDIT,
  OPTIMIZER_PACKER_PLANT_BEEF_INPUT_REMOVE,
  EDIT_OPTIMIZER_TRANSPORT,
} from '../actions/actions_optimizer';

function replacePacker(allPackerBeefInputs, editedPackerBeefInput) {
  const newPackerBeefInputs = [];
  allPackerBeefInputs.forEach(element => {
    if (element.uid === editedPackerBeefInput.uid) {
      newPackerBeefInputs.push(editedPackerBeefInput);
    } else {
      newPackerBeefInputs.push(element);
    }
  });
  return newPackerBeefInputs;
}

function removePacker(allPackerBeefInputs, removedPackerBeefInput) {
  const newPackerBeefInputs = [];
  allPackerBeefInputs.forEach(element => {
    if (element.uid !== removedPackerBeefInput.uid) {
      newPackerBeefInputs.push(element);
    }
  });
  return newPackerBeefInputs;
}

function replaceTransport(allTransport, editedPackerBeefInput) {
  const newTransports = [];
  allTransport.forEach(element => {
    if (
      element.packer_plant_id === editedPackerBeefInput.packer_plant_id &&
      element.grinder_uid === editedPackerBeefInput.grinder_uid
    ) {
      newTransports.push(editedPackerBeefInput);
    } else {
      newTransports.push(element);
    }
  });
  return newTransports;
}

const optimizer_data = (state = false, action) => {
  const newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case LOAD_OPTIMIZER_DATA:
      return action.payload;
    case OPTIMIZER_PACKER_PLANT_BEEF_INPUT_ADDED:
      newState.packer_input_product_inventory_items.unshift(action.payload);
      return newState;
    case OPTIMIZER_PACKER_PLANT_BEEF_INPUT_EDIT:
      newState.packer_input_product_inventory_items = replacePacker(
        newState.packer_input_product_inventory_items,
        action.payload
      );
      return newState;
    case OPTIMIZER_PACKER_PLANT_BEEF_INPUT_REMOVE:
      newState.packer_input_product_inventory_items = removePacker(
        newState.packer_input_product_inventory_items,
        action.payload
      );
      return newState;
    case EDIT_OPTIMIZER_TRANSPORT:
      newState.transport_costs = replaceTransport(newState.transport_costs, action.payload);
      return newState;
    default:
      return state;
  }
};

export default optimizer_data;
