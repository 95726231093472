import {
  LOAD_EDIT_PO_LOGS,
  LOAD_GRINDER_PO_LOGS,
  LOAD_PACKER_PLANT_PO_LOGS,
  LOAD_PO_LOG_ORDER,
  LOAD_PO_LOGS,
  LOAD_PO_LOGS_TABS,
  REFRESH_ORDERS,
} from '../actions/actions_pologs';

import { REPLACE_ORDER, DELETE_ORDER } from '../actions/actions_orders';
import { replacePoLogsInState } from '../helpers';

const initialState = {
  buttons_access: [],
  po_logs: [],
  orders: [],
  edit_po_logs: [],
  grinder_po_logs: [],
  packer_po_logs: [],
  po_log_order: null,
};

const pologs = (state = initialState, action) => {
  const po_logs = replacePoLogsInState(state.po_logs, action.payload);
  const packer_po_logs = replacePoLogsInState(state.packer_po_logs, action.payload);
  const grinder_po_logs = replacePoLogsInState(state.grinder_po_logs, action.payload);

  switch (action.type) {
    case LOAD_PO_LOGS:
      return {
        ...state,
        po_logs: action.payload,
      };

    case LOAD_PO_LOGS_TABS:
      return {
        ...state,
        buttons_access: action.payload,
      };

    case LOAD_EDIT_PO_LOGS:
      return {
        ...state,
        edit_po_logs: action.payload,
      };

    case LOAD_PACKER_PLANT_PO_LOGS:
      return {
        ...state,
        packer_po_logs: action.payload,
      };
    case LOAD_GRINDER_PO_LOGS:
      return {
        ...state,
        grinder_po_logs: action.payload,
      };
    case REPLACE_ORDER:
      return {
        ...state,
        po_logs,
        packer_po_logs,
        grinder_po_logs,
        po_log_order: null,
      };
    case REFRESH_ORDERS:
      state.po_logs = replacePoLogsInState(state.po_logs, action.payload);
      state.grinder_po_logs = state.po_logs;
      state.packer_po_logs = state.po_logs;
      return {
        ...state,
        po_logs,
        packer_po_logs,
        grinder_po_logs,
      };
    case LOAD_PO_LOG_ORDER:
      return {
        ...state,
        po_log_order: action.payload,
      };
    case DELETE_ORDER: {
      return {
        ...state,
        po_logs,
        packer_po_logs,
        grinder_po_logs,
      };
    }
    default:
      return state;
  }
};

export default pologs;
