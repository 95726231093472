import { SETTINGS_CONFIG_FETCHED } from '../actions/action_settings_config';

const settingsConfig = (settingsConfig_ = [], action) => {
  switch (action.type) {
    case SETTINGS_CONFIG_FETCHED:
      return action.payload;
    default:
      return settingsConfig_;
  }
};

export default settingsConfig;
