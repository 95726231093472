import { FDR_ITEMS_FETCHED, FDR_ITEM_UPDATED, FDR_ITEM_REMOVED, FDR_ITEM_ADDED } from '../actions/actions_fdr_items';

const fdr_items = (FDRItems = [], action) => {
  switch (action.type) {
    case FDR_ITEMS_FETCHED:
      return action.payload;
    case FDR_ITEM_ADDED:
      return [...FDRItems, action.payload];
    case FDR_ITEM_UPDATED:
      return [...FDRItems.filter(item => item.id !== action.payload.id), action.payload];
    case FDR_ITEM_REMOVED:
      return [...FDRItems.filter(item => item.id !== action.payload.id)];
    default:
      return FDRItems;
  }
};

export default fdr_items;
