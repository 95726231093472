import {
  TRANSPORTER_ADDED,
  TRANSPORTER_EDIT,
  TRANSPORTER_REMOVED,
  TRANSPORTERS_FETCHED,
} from '../actions/actions_transporters';
import { removeObjectFromArray, replaceObjectInArray } from '../helpers';

const transporters = (transporters_ = [], action) => {
  let newTransporters = false;

  switch (action.type) {
    case TRANSPORTERS_FETCHED:
      return action.payload;
    case TRANSPORTER_ADDED:
      newTransporters = JSON.parse(JSON.stringify(transporters_));
      newTransporters.push(action.payload);
      return newTransporters;
    case TRANSPORTER_EDIT:
      newTransporters = JSON.parse(JSON.stringify(transporters_));
      return replaceObjectInArray(newTransporters, action.payload);
    case TRANSPORTER_REMOVED:
      newTransporters = JSON.parse(JSON.stringify(transporters_));
      return removeObjectFromArray(newTransporters, action.payload);
    default:
      return transporters_;
  }
};

export default transporters;
