import { INTERESTS_FETCHED, INTEREST_UPDATED, INTEREST_REMOVED, INTEREST_ADDED } from '../actions/actions_interests';

const interests = (interests_ = [], action) => {
  const newInterests = [...interests_.filter(item => item.id !== action.payload.id), action.payload];
  switch (action.type) {
    case INTERESTS_FETCHED:
      return action.payload.map(interest => ({ ...interest, rate: interest.rate * 100 }));
    case INTEREST_ADDED:
      return [...interests_, action.payload];
    case INTEREST_UPDATED:
      return newInterests.map(interest => ({
        ...interest,
        rate: interest.rate * 100,
      }));
    case INTEREST_REMOVED:
      return [...interests_.filter(item => item.id !== action.payload.id)];
    default:
      return interests_;
  }
};

export default interests;
