import { SUPPORT_TICKET_ADDED, SUPPORT_TICKET_UPDATED, SUPPORT_TICKETS_FETCHED } from '../actions/actions_support';
import { replaceObjectInArray } from '../helpers';

const defaultState = {
  submitted: false,
  tickets: [],
};

const tickets = (tickets_ = defaultState, action) => {
  let newTickets = JSON.parse(JSON.stringify(tickets_.tickets));
  defaultState.tickets = newTickets;

  switch (action.type) {
    case SUPPORT_TICKET_ADDED:
      newTickets.push(action.payload);
      return {
        ...defaultState,
        submitted: true,
      };
    case SUPPORT_TICKET_UPDATED:
      newTickets = replaceObjectInArray(newTickets, action.payload);
      return {
        ...defaultState,
        tickets: newTickets,
      };
    case SUPPORT_TICKETS_FETCHED:
      return {
        ...defaultState,
        tickets: action.payload,
      };
    default:
      return defaultState;
  }
};

export default tickets;
