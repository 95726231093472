import { format, parse, getMonth, getYear } from 'date-fns';
import { group } from 'd3';

export default function yearOnYear(data) {
  const byMonth = group(data, ({ interval }) => getMonth(parse(interval, 'MMM yyyy', new Date())));

  return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(monthIndex => {
    return {
      interval: format(new Date(new Date().getFullYear(), monthIndex, 1), 'MMM'),
      ...(byMonth.get(monthIndex) || []).reduce((values, { interval, value }) => {
        // eslint-disable-next-line no-param-reassign
        values[getYear(parse(interval, 'MMM yyyy', new Date()))] = value || 0;
        return values;
      }, {}),
    };
  });
}

export function getUniqueYears(data) {
  const byYear = group(data, interval => getYear(parse(interval, 'MMM yyyy', new Date())));
  return Array.from(byYear.keys());
}

/**
 * Filter out the data that not in the current and previous periods
 * and sort it in ascending order
 */
export const getTwoPeriods = byPeriodData => {
  const byYearMap = group(byPeriodData, ({ date }) => getYear(new Date(date)));
  return Array.from(byYearMap)
    .sort((a, b) => b[0] - a[0])
    .slice(0, 2)
    .map(g => g[1])
    .flat()
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
};
