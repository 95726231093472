import {
  SUPPORT_EMAIL_ADDED,
  SUPPORT_EMAIL_DELETED,
  SUPPORT_EMAIL_UPDATED,
  SUPPORT_EMAILS_FETCHED,
} from '../actions/actions_support';
import { removeObjectFromArray, replaceObjectInArray } from '../helpers';

const support_emails = (support_emails_ = [], action) => {
  const newSupportEmails = JSON.parse(JSON.stringify(support_emails_));

  switch (action.type) {
    case SUPPORT_EMAILS_FETCHED:
      return action.payload;
    case SUPPORT_EMAIL_ADDED:
      action.payload.editMode = false;
      newSupportEmails.push(action.payload);
      return newSupportEmails;
    case SUPPORT_EMAIL_UPDATED:
      return replaceObjectInArray(newSupportEmails, action.payload);
    case SUPPORT_EMAIL_DELETED:
      return removeObjectFromArray(newSupportEmails, action.payload);
    default:
      return support_emails_;
  }
};

export default support_emails;
