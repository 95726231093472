import {
  BEEF_OUTPUT_INVENTORY_ITEMS_FETCHED,
  BEEF_OUTPUT_INVENTORY_ITEMS_ADDED,
} from '../actions/actions_output_product_inventory_items';

const output_product_inventory_items = (inventory_items = [], action) => {
  const newInventoryItems = JSON.parse(JSON.stringify(inventory_items));
  switch (action.type) {
    case BEEF_OUTPUT_INVENTORY_ITEMS_FETCHED:
      return action.payload;
    case BEEF_OUTPUT_INVENTORY_ITEMS_ADDED:
      newInventoryItems.push(action.payload);
      return newInventoryItems;
    default:
      return inventory_items;
  }
};

export default output_product_inventory_items;
