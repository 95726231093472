import React from 'react';
import styles from './DetailPageHeading.module.css';
import PropTypes from 'prop-types';
import { Text } from '@chakra-ui/react';

function DetailPageHeading({ title }) {
  return (
    <Text textStyle="2xl" className={styles.pageHeading}>
      {title}
    </Text>
  );
}

DetailPageHeading.propTypes = {
  title: PropTypes.string,
};

export default DetailPageHeading;
